<template>
  <div>
      <v-btn @click="refreshClients">Ververs clients</v-btn>
      <v-textarea
      v-model="message"
      ></v-textarea>
      <v-btn @click="sendMessage">Verzenden</v-btn>
  </div>
</template>

<script>
import { sendMessage } from '../js/functions'
export default {
    name: "Admin",
data() {
    return {
        message: ""
    }
},
methods: {
   async refreshClients() {
  try {
        let response = await sendMessage("refreshClients",{})
        console.log(response)
        } catch (e) {
            console.error(e)
        }
    },
    async sendMessage(){
        try {
        let response = await sendMessage("sendMessage",{message:this.message})
        console.log(response)
        } catch (e) {
            console.error(e)
        }
    }
}
}
</script>

<style>

</style>